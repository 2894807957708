import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import logo from '../../../static/logo.png';
import { LanguageContext } from '../../context';
import styles from './home.module.css';

function HomeTemplate() {
  const locale = useContext(LanguageContext);
  const url = locale === 'fr' ? 'actualites' : 'news';
  return (
    <>
      <Helmet title="AWAW" />
      <div className={styles.imgContainer}>
        <Link to={`/${url}`}>
          <img src={logo} className={styles.img} alt="logo" />
        </Link>
      </div>
    </>
  );
}

export default HomeTemplate;
