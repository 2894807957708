import React from 'react';
import HomeTemplate from '../templates/home';
import { LanguageContextProvider } from '../context';

function RootIndex() {
  return (
    <LanguageContextProvider>
      <HomeTemplate />
    </LanguageContextProvider>
  );
}

export default RootIndex;
